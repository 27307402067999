import styled from "@emotion/styled"
import React from "react"
import useNewsletterSignup from "../../hooks/use-newsletter-signup"
import Link from "../base/link"
import Button from "../base/buttons"
import Input from "../base/input-field"

import IconMastercard from "../../assets/pay-icons/mastercard-white.svg"
import IconVisa from "../../assets/pay-icons/visa-white.svg"
import IconVisaelectron from "../../assets/pay-icons/visaelectron-white.svg"
import IconKlarna from "../../assets/pay-icons/klarna-white.svg"
import IconMobilepay from "../../assets/pay-icons/mobilepay-white.svg"
import IconDankort from "../../assets/pay-icons/dankort-white.svg"

// FOOTER
const SiteFooter = styled.footer`
  margin-top: ${({theme}) => theme.marginTop.header}px;
`

// BRANDING
/*
const SiteFooterBranding = styled(Link)`
  display: block;
  margin-bottom: -2%;
  font-size: 22vw;
  line-height: 1.1;
  text-align: center;
  overflow: hidden;
  word-wrap: normal;
`
*/

// CONTENT LAYOUT
const SiteFooterContentWrapper = styled.div`
  border-bottom: ${({theme}) => theme.borders.thin};

  ${({ theme }) => theme.bp.desktop} {
    display: flex;
    flex-wrap: wrap;
  }
`

const SiteFooterItem = styled.div`
  min-height: ${({ theme }) => theme.height[0]}px;
  padding: 15px;
  border-top: ${({theme}) => theme.borders.thin};

  &:nth-of-type(3) {
    padding: 0;
  }

  ${({ theme }) => theme.bp.desktop} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;

    &:nth-of-type(1),
    &:nth-of-type(4) {
      width: 60%;
      border-right: ${({theme}) => theme.borders.thin};
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 40%;
    }
  }

  ${({ theme }) => theme.bp.desktop} {
    &:nth-of-type(3) {
      order: 4;
    }

    &:nth-of-type(4) {
      order: 3;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

// NAVIGATION
const SiteFooterNav = styled.nav`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.bp.desktop} {
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
`

const SiteFooterNavItem = styled.span`
  display: block;

  & + & {
    margin-top: 10px;
  }

  ${({ theme }) => theme.bp.desktop} {
    padding-top: 5px;
    padding-bottom: 5px;

    & + & {
      margin-top: 0;
      margin-left: 30px;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    & + & {
      margin-left: 40px;
    }
  }
`

const SiteFooterNavLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;
  }
`

// SOCIAL MEDIA
const SiteFooterSocial = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;

  ${({ theme }) => theme.bp.desktop} {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }
`

const SiteFooterSocialItem = styled.li`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;

  & + & {
    margin-top: 10px;
  }

  ${({ theme }) => theme.bp.desktop} {
    padding-top: 5px;
    padding-bottom: 5px;

    & + & {
      margin-top: 0;
      margin-left: 30px;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;

    & + & {
      margin-left: 40px;
    }
  }
`

const SiteFooterSocialItemLink= styled.a`
  color: ${({ theme }) => theme.colors.darkGreen};
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }
`

// PAYMENT OPTIONS
const SiteFooterPaymentOptions = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 0;
  list-style: none;

  ${({ theme }) => theme.bp.desktop} {
    margin-bottom: 0;
  }
`

const SiteFooterPaymentOptionsItem = styled.li`
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 0;

  & + & {
    margin-left: 5px;
  }
`

// COPYRIGHT
const SiteFooterCopyright = styled.p`
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  color: ${({ theme }) => theme.colors.lightGreen};
  text-align: center;

  ${({ theme }) => theme.bp.desktop} {
    text-align: left;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
`

// NEWSLETTER SIGNUP
const SiteFooterSignupHeading = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  text-transform: uppercase;
  letter-spacing: .06em;

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
`

const SiteFooterSignupMessage = styled.p`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  min-height: ${({ theme }) => theme.height[0]}px;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  color: ${({ theme }) => theme.colors.almostWhite};

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const SiteFooterSignupForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`

const SiteFooterSignupInput = styled(Input)`
  && {
    width: 75%;
    min-height: ${({ theme }) => theme.height[0]}px;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }

  ${({ theme }) => theme.bp.desktop} {
    && {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`

const SiteFooterSignupButton = styled(Button)`
  width: 25%;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGreen};
  }
`

const SiteFooterSignupDisclaimer = styled.p`
  margin-bottom: 0;
  padding: 10px 15px;
  border-top: ${({theme}) => theme.borders.thin};
  font-size: ${({ theme }) => theme.fontSizes[0]}px;

  ${({ theme }) => theme.bp.desktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Footer = () => {
  const { handleEmailChange, handleSignup, signingUp, state } =
    useNewsletterSignup()

  return (
    <SiteFooter>
      {/*
      <SiteFooterBranding to="/">
        Palmes
      </SiteFooterBranding>
      */}
      <SiteFooterContentWrapper>
        <SiteFooterItem>
          <SiteFooterNav>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/contact">
                Contact
              </SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/stores">
                Stores
              </SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/shipping-returns">
                Shipping + Returns
              </SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/privacy-policy">
                Privacy Policy
              </SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/careers">
                Careers
              </SiteFooterNavLink>
            </SiteFooterNavItem>
          </SiteFooterNav>
          <SiteFooterSocial>
            <SiteFooterSocialItem>
             <SiteFooterSocialItemLink href="https://www.instagram.com/palmes.society/" target="_blank"
            rel="noreferrer">
                Instagram
              </SiteFooterSocialItemLink>
            </SiteFooterSocialItem>
            <SiteFooterSocialItem>
              <SiteFooterSocialItemLink href="https://www.facebook.com/Palmes.society" target="_blank"
            rel="noreferrer">
                Facebook
              </SiteFooterSocialItemLink>
            </SiteFooterSocialItem>
          </SiteFooterSocial>
        </SiteFooterItem>
        <SiteFooterItem>
          <SiteFooterSignupHeading>Sign up to our newsletter</SiteFooterSignupHeading>
        </SiteFooterItem>
        <SiteFooterItem>
        {state.signupComplete ? (
              <SiteFooterSignupMessage>
                Thank you!
              </SiteFooterSignupMessage>
            ) : (
              <>
                <SiteFooterSignupForm>
                  <SiteFooterSignupInput
                    placeholder={"Enter your email"}
                    onChange={handleEmailChange}
                    disabled={state.signupComplete}
                  />
                  <SiteFooterSignupButton
                    variant="primary"
                    loading={signingUp}
                    disabled={state.signupComplete}
                    onClick={handleSignup}
                  >
                    Join
                  </SiteFooterSignupButton>
                  {state.email && (
                  <SiteFooterSignupDisclaimer>
                    Sign me up for newsletters and communications from Palmes.
                    By signing up, you confirm that you are above 15 years old
                    and have reviewed Privacy Policy. Unsubscribe any time
                    through the link at the bottom of our newsletter.
                  </SiteFooterSignupDisclaimer>
                  )}
                </SiteFooterSignupForm>
              </>
            )}
        </SiteFooterItem>
        <SiteFooterItem>
          <SiteFooterPaymentOptions>
            {/* <SiteFooterPaymentOptionsItem>
              <IconMobilepay />
            </SiteFooterPaymentOptionsItem> */}
            <SiteFooterPaymentOptionsItem>
              <IconDankort />
            </SiteFooterPaymentOptionsItem>
            <SiteFooterPaymentOptionsItem>
              <IconVisa/>
            </SiteFooterPaymentOptionsItem>
            <SiteFooterPaymentOptionsItem>
              <IconVisaelectron />
            </SiteFooterPaymentOptionsItem>
            <SiteFooterPaymentOptionsItem>
              <IconMastercard />
            </SiteFooterPaymentOptionsItem>
            {/* <SiteFooterPaymentOptionsItem>
              <IconKlarna />
            </SiteFooterPaymentOptionsItem> */}
          </SiteFooterPaymentOptions>
          <SiteFooterCopyright>&reg; 2023 Palmes. All rights reserved.</SiteFooterCopyright>
        </SiteFooterItem>
      </SiteFooterContentWrapper>
    </SiteFooter>
  )
}

export default Footer
